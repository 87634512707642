import React from 'react'
import Navbar from "../components/Navbar"
const About = () => {
    return (
        <div>
            <Navbar/>
            About
        </div>
    )
}

export default About