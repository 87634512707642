import { Image } from '@chakra-ui/react'
import React from 'react'

const ProfileImage = () => {
    return (
        <Image
            src='https://avatars.githubusercontent.com/u/73957024?v=4'
            borderRadius="full"
            boxSize="150px"
        />
    )
}

export default ProfileImage