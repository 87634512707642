import { Button, useColorMode, HStack, Spacer } from '@chakra-ui/react'
import { FiSun, FiMoon, FiMenu, FiHome, FiUser, FiTool, FiCopy } from 'react-icons/fi'
import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, } from '@chakra-ui/react'
const ToggleTheme = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <HStack
            w="full"
            m={0}
            display="flex"
            spacing={2}
            justifyContent="end"
            alignContent="end">
            {/* <Menu isLazy>
                <MenuButton
                    m={5}
                    // color={colorMode === "dark" ? "#71b7ff" : "#0a69da"}
                    fontSize="xl"
                    bg="none"
                    transition='all 0.2s'
                    _hover={{
                        bg: "none"
                    }}>
                    <FiMenu />

                </MenuButton>
                <MenuList
                    bg={colorMode === "dark" ? "#1A202C" : "#f5f5f5"}>
                    <MenuItem
                        icon={<FiHome />}
                        bg={colorMode === "dark" ? "#1A202C" : "#f5f5f5"}
                        _hover={{
                            textDecoration: "none",
                            bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                        }}>

                        Home
                    </MenuItem>
                    <MenuItem
                        icon={<FiUser />}
                        bg={colorMode === "dark" ? "#1A202C" : "#f5f5f5"}
                        _hover={{
                            textDecoration: "none",
                            bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                        }}>
                        About

                    </MenuItem>
                    <MenuItem
                        icon={<FiTool />}
                        bg={colorMode === "dark" ? "#1A202C" : "#f5f5f5"}
                        _hover={{
                            textDecoration: "none",
                            bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                        }}>
                        Skills
                    </MenuItem>
                    <MenuItem
                        icon={<FiCopy />}
                        bg={colorMode === "dark" ? "#1A202C" : "#f5f5f5"}
                        _hover={{
                            textDecoration: "none",
                            bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                        }}>
                        Projects
                    </MenuItem>
                </MenuList>
            </Menu> */}

            <Spacer />
            <Button
                m={2}
                // color={colorMode === "dark" ? "#71b7ff" : "#0a69da"}
                fontSize="xl"
                marginRight={10}
                onClick={toggleColorMode}
                bg="none"
                _hover={{
                    bg: "none"
                }}>
                {colorMode === "light" ? <FiMoon /> : <FiSun />}
            </Button>


        </HStack>
    )
}

export default ToggleTheme