import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
    initialColorMode: "dark",
    useSystemColorMode: false,
    font: "Montserrat",
    styles: {
        global: (props) => ({
            'html, body': {
                bg: props.colorMode==="dark"?"#1A202C":"#f5f5f5",
                color: props.colorMode==="dark"?"#dadbdb":"#393e46"
            },
        }),
    },

})

export default theme