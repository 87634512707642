import { VStack, Heading, Text, HStack, Link } from "@chakra-ui/react";
import ProfileImage from "../components/Home/ProfileImage";
import Socials from "../components/Home/Socials"
import Typing from "../components/Home/Typing"
import Navbar from "../components/Navbar"
function App() {
    return (
        <VStack h="100vh" spacing={0}>
            <Navbar />
            <VStack m={0} h="100vh" w="100%" display="flex" alignItems="center" justifyContent="center">
                <VStack display="flex" justifyContent="center" alignItems="center" p={4} borderRadius={10}>
                    <ProfileImage />
                    <Heading >Alok Mathur</Heading>
                    <Text fontSize="xl"><Typing /></Text>
                    <Socials />
                </VStack>
            </VStack>
            <HStack p={3}>
                <Text color="gray">© {new Date().getFullYear()} <Link href="https://imalok.me" target="_blank">Alok Mathur</Link></Text>
            </HStack>
        </VStack>
    );
}

export default App;
