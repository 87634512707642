import { Button, HStack, Link, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { FiLink, FiFileText } from "react-icons/fi"
import socials from '../../data/socials'
import SocialButton from './SocialButton'
const Socials = () => {
    const { colorMode } = useColorMode()
    return (
        <>
            <HStack spacing="0">
                {socials.map((social) => {
                    return <SocialButton key={social.id} social={social} />
                })}
            </HStack>
            <HStack spacing={3}>
                <Button
                    as={Link}
                    href="https://alok.studio/"
                    target="_blank"
                    bg={colorMode === "dark" ? "#2c313d" : "#dddddd"}
                    _hover={{
                        textDecoration: "none",
                        bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                    }}
                    leftIcon={<FiLink />}>
                    Portfolio
                </Button>
                <Button
                    as={Link}
                    href="https://drive.google.com/file/d/1Hi_0EAG4KcQf0kJRz8Vh3EIUwdjgBAPL/view?usp=drive_link"
                    target="_blank"
                    bg={colorMode === "dark" ? "#2c313d" : "#dddddd"}
                    _hover={{
                        textDecoration: "none",
                        bg: colorMode === "dark" ? "#3f444e" : "#e2e8f0"
                    }}
                    leftIcon={<FiFileText />}>
                    Resume
                </Button>
            </HStack>
        </>
    )
}

export default Socials
