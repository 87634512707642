import { Button, Link, useColorMode } from '@chakra-ui/react'
import React from 'react'

const SocialButton = ({ social }) => {
    const { colorMode } = useColorMode()
    return (
        <Button
            as={Link}
            href={social.url}
            target="_blank"
            fontSize="xl"
            bg="none"
            p="0"
            _hover={{
                bg: colorMode === "dark" ? "#2C313D" : "#dddddd"
            }}>
            {social.icon}
        </Button>
    )
}

export default SocialButton