import React from 'react'
import ReactDOM from 'react-dom/client'
import Home from './pages/Home';
import theme from './config/theme'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ChakraProvider } from '@chakra-ui/react';
import About from './pages/About';

const router = createBrowserRouter([
    {
        "path": "/",
        "element": <Home />
    },
    {
        "path": "/about",
        element: <About />
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            
            <RouterProvider router={router} />
        </ChakraProvider>
    </React.StrictMode>
);
