
import { SiLeetcode, SiGeeksforgeeks } from "react-icons/si"
import { FiGithub, FiLinkedin, FiInstagram, FiTwitter, FiMail } from "react-icons/fi"
const socials = [
    {
        id: 0,
        site: "Linkedin",
        url: "https://linkedin.com/in/alok-mathur",
        icon: <FiLinkedin />
    },
    {
        id: 1,
        site: "Github",
        url: "https://github.com/alok27a",
        icon: <FiGithub />
    },
    {
        id: 2,
        site: "Instagram",
        url: "https://instagram.com/alok27a",
        icon: <FiInstagram />
    },
    {
        id: 3,
        site: "Twitter",
        url: "https://twitter.com/alok27a",
        icon: <FiTwitter />
    },
    {
        id: 4,
        site: "Mail",
        url: "mailto:alok27a.work@gmail.com",
        icon: <FiMail />
    },
    {
        id: 5,
        site: "Leetcode",
        url: "https://leetcode.com/alok27a",
        icon: <SiLeetcode />
    },
    {
        id: 6,
        site: "Geeks For Geeks",
        url: "https://auth.geeksforgeeks.org/user/alok27a",
        icon: <SiGeeksforgeeks />
    }
]

export default socials
