import React from 'react'
import Typed from 'react-typed'
const Typing = () => {
    return (
        <div>
            <Typed
                strings={["Software Engineer","Computer Science Student", "App Developer", "Web Developer", "Open Source Contributor", "SIH'22 Winner 🏆"]}
                typeSpeed={10}
                backSpeed={10}
                loop
                showCursor={false}
            />
            <br />
        </div>
    )
}

export default Typing